app.controller('datatable', ['$scope', '$http', '$auth', 'DTOptionsBuilder','DTColumnBuilder', '$q', function($scope, $http, $auth, DTOptionsBuilder, DTColumnBuilder, $q) {

    $scope.dtOptions = DTOptionsBuilder
    .newOptions()
    .withFnServerData(serverData)
    .withOption('processing', true)
    .withOption('serverSide', true)
    .withDisplayLength(10)
    .withBootstrap()
    .withOption('rowCallback', rowCallback);

    function serverData(sSource, aoData, fnCallback, oSettings){

        var draw   = aoData[0].value;
        var order  = aoData[2].value;
        var start  = aoData[3].value;
        var length = aoData[4].value;
        var search = aoData[5].value;

        $http.get('/api/category').then(function(result){
            result = result.data;
            var records = {
                    'draw': draw,
                    'recordsTotal': result.recordsTotal,
                    'recordsFiltered': result.recordsFiltered,
                    'data': result.data
                };
            fnCallback(records);
        }, function(response) {
            deferred.reject(response);
        });
    }

    function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        // Unbind first in order to avoid any duplicate handler (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', nRow).unbind('click');
        $('td', nRow).bind('click', function() {
            $scope.$apply(function() {
                console.log(aData);
            });
        });
        return nRow;
    }

    $scope.dtColumns = [
        DTColumnBuilder.newColumn('id').withTitle('#'),
        DTColumnBuilder.newColumn('name').withTitle('Nome')
    ];

}]);
