
var people = angular.module('people', ['ui.router', 'people.show.html']);

people.config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {

    $urlRouterProvider.otherwise("/");

    // Now set up the states
    $stateProvider

    .state('people', {
        url: "/people",
        templateUrl: "people.show.html"
    });

}]);

angular.module("people.show.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('people.show.html',
        "<div class=\"starter-template\">"+
        "    <h1>Página de pessoas</h1>"+
        "    <p class=\"lead\">Use this document as a way to quickly start any new project.<br> All you get is this text and a mostly barebones HTML document.</p>"+
        "</div>"
    );
}]);
